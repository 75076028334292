import {stateType} from "@/views/ShopAdmin/SiteOrder/Model/indexType";

export const navList:stateType[] = [
    {
        value:"序号",
        num:0,
        state:""
    },{
        value:"店铺",
        num:0,
        state:""
    },{
        value:"待接单",
        num:0,
        state:"totalDelivery"
    },{
        value:"待取货",
        num:0,
        state:"totalPickUp"
    },{
        value:"待送达",
        num:0,
        state:"totalArrive"
    },{
        value:"已完成",
        num:0,
        state:"totalComplete"
    },
]
