import {addressOrderNum} from "@/Api/ShopAdmin";
import Storage from "@/util/Storage"
import { navList } from "@/views/ShopAdmin/SiteOrder/Model";

export function getOrderNum(areaId:string|number) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( addressOrderNum({userId,token},{ areaId }).then(res=>{
        let data = { ...res.data,stateList:[],orderList:[] }
        navList.length && navList.forEach((item,index)=>{
            if ( item.state ){
                item.num = res.data[item.state]
            }else{
                item.num = 0
            }
        })
        let List:any[] = []
        res.data.deliveryInfo.length && res.data.deliveryInfo.forEach((item:any,index:number)=>{
            List.push([
                index + 1,
                item.storeName || '',
                item.delivery || 0,
                item.pickUp || 0,
                item.arrive || 0,
                item.complete || 0,
            ])
        })
        data.stateList = navList
        data.orderList = List
        return data
    }) )
}
